<template>
    <v-speed-dial
      class="workspace-action-dial"
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <v-btn
        slot="activator"
        v-model="fab"
        color="accent"
        dark
        fab
      >
        <v-icon>more_horiz</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn
        v-if="showSend"
        class="send"
        fab
        dark
        small
        @click="sendClick"
      >
        <v-icon title="Send">send</v-icon>
      </v-btn>
      <v-btn
        v-if="showNew"
        class="add"
        fab
        dark
        small
        @click="newClick"
      >
        <v-icon title="New (ctrl-alt-n)">add</v-icon>
      </v-btn>
      <v-btn
        class="save"
        v-if="showSave"
        fab
        dark
        small
        @click="saveClick"
      >
        <v-icon title="Save (ctrl-alt-s)">save</v-icon>
      </v-btn>
      <v-btn
        v-if="showCancel"
        class="cancel"
        fab
        dark
        small
        @click="cancelClick"
      >
        <v-icon title="Cancel (ctrl-alt-c)">close</v-icon>
      </v-btn>
        <v-btn
          v-if="showImport"
          class="download"
          fab
          dark
          small
          @click="importClick"
        >
          <v-icon title="Import">system_update_alt</v-icon>
      </v-btn>
      <v-btn
        v-if="showDownload"
        class="download"
        fab
        dark
        small
        @click="downloadClick"
      >
        <v-icon title="Download">get_app</v-icon>
      </v-btn>
      <v-btn
        v-if="showUpload"
        class="upload"
        fab
        dark
        small
        @click="uploadClick"
      >
        <v-icon title="Upload">publish</v-icon>
      </v-btn>
      <v-btn
        v-if="showDelete"
        class="delete"
        fab
        dark
        small
        @click="deleteClick"
      >
        <v-icon title="Delete (ctrl-alt-d)">delete</v-icon>
      </v-btn>
      <v-btn
        v-if="showSendEnrollment"
        class="send"
        fab
        dark
        small
        @click="sendEnrollmentClick"
      >
        <v-icon title="Send Enrollment">fingerprint</v-icon>
      </v-btn>
    </v-speed-dial>
</template>

<style>
  .workspace-action-dial {
    z-index: 30;
    position: absolute;
    right: 50px;
    top: 80px !important;
  }
</style>

<script>
export default {
  name: 'WorkspaceActionDial',
  props: {
    showSave: Boolean,
    showNew: Boolean,
    showDelete: Boolean,
    showCancel: Boolean,
    showSend: Boolean,
    showUpload: Boolean,
    showDownload: Boolean,
    showImport: Boolean,
    showSendEnrollment: Boolean,
  },
  data: () => ({
    direction: 'left',
    fab: false,
    fling: true,
    hover: true,
    tabs: null,
    top: true,
    right: true,
    bottom: false,
    left: false,
    transition: 'slide-y-reverse-transition',
  }),
  methods: {
    saveClick() {
      this.$emit('dialSave');
    },
    newClick() {
      this.$emit('dialNew');
    },
    deleteClick() {
      this.$emit('dialDelete');
    },
    cancelClick() {
      this.$emit('dialCancel');
    },
    sendClick() {
      this.$emit('dialSend');
    },
    uploadClick() {
      this.$emit('dialUpload');
    },
    downloadClick() {
      this.$emit('dialDownload');
    },
    importClick() {
      this.$emit('dialImport');
    },
    sendEnrollmentClick() {
      this.$emit('dialSendEnrollment');
    },
  },
};
</script>
